body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-title-wrapper {
  margin-top: 64px;
  margin-bottom: 24px;

}

.page-title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;

  /* Interface Dark 1 */

  color: #1B1F3B;
}

.page-subtitle-wrapper {
  margin-bottom: 16px;

}

.page-subtitle {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */


  /* Interface Dark 1 */

  color: #1B1F3B;
}

.text-format {
  width: 647px;
  display: block;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1b1f3b;
}